.podcast-wrapper {
    width: 100%;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.podcast-wrapper .podcast-item {
    margin: 5px;
    padding: 15px;
    background: #00000024;
    border-radius: 3px;
    cursor: pointer;

}

.podcast-wrapper .podcast-item:hover {
    color: rgb(230, 114, 25);
    background: #00000044;
}

.podcast-wrapper .podcast-item .description {
    color: #fff;
    font-size: 13px;
}

.podcast-wrapper .podcast-item .title {
    color: #fff;
    font-size: 15px;
}

.podcast-wrapper .podcast-item .title:hover {
    color: rgb(230, 114, 25);
}

.modal-close-wrapper {
    text-align: right;
}
.modal-close {
    background: #000;
    border-radius: 2px;
    padding: 2px;
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
}

.modal-close:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

@media all and (max-width:900px) {
    .podcast-wrapper {
        width: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin-top: 35px;
    }
}