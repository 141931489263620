/* 20 sept 2020 */
.App {
  text-align: center;
  padding-top: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 52px;
  padding-right: 35px;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 7.3;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}
.d-none {
  display: none;
}
._3nmo_success {
  color: #0b881e;
  text-align: center;
}
._3nmo_success ._3nmo_success {
  -webkit-animation-name: GrX0c;
  animation-name: GrX0c;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
._3nmo_success:empty {
  display: none;
}

/* sept 23 */
.username {
  margin-left: 0.3rem;
  position: relative;
  padding-right: 10px;
  margin-right: 12px;
  margin-right: 0;
  flex-basis: 33%;
  font-size: 1rem;
  font-weight: 600;
}
@media (min-width: 768px) {
  .username:before {
    left: 170px;
  }
}
.signout {
  color: #26262d;
  padding: 0.1rem 0;
  font-weight: 400;
  display: inline-block;
  position: relative;
  transition: opacity 0.3s;
  color: #6e93ab;
  line-height: 1.5;
  cursor: pointer;
}
.sign {
  position: absolute;
  padding: 0.8rem 1.5rem;
  border-radius: 2px;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  display: flex;
  width: 16.67rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(32, 32, 38, 0.1);
}
@media (max-width: 768px) {
  .sign {
    width: 100%;
    position: absolute;
    display: flex;
  }
  .signpadding {
    padding-left: 104px;
  }
  .signin {
    width: 20px;
  }
  .watchBottom {
    margin-left: 25px !important;
  }
}
._1xPbF {
  margin: 5px 0;
  border-bottom: 1px solid rgba(28, 31, 41, 0.88);
  opacity: 0.1;
}
.signpadding {
  padding-left: 1040px;
}
text-head {
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-top: 90px;
}
/* 24th sept */
.subscribe-btn {
  background: none;
  color: #6e93ab;
  border: 1px solid #6e93ab;
  width: 170px;
  height: 50px;
  outline: none;
  margin-top: 35px;
  font-size: 17px;
  border-radius: 25px;
  transition-duration: 1s;
  margin-left: 25px;
}
.subscribe-btn:hover {
  background: #6e93ab !important;
  color: #fff !important;
}
.mytitle {
  padding: 12px;
  width: 250px;
  box-shadow: 0 10px 24px 0 rgba(238, 241, 250, 0.2);
  margin-bottom: 10px;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  margin-right: 10px;
}
.subscribe-box {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 10px 24px 0 rgba(82, 91, 115, 0.12);
}

.player-back {
  position: absolute;
  top: 60px;
  left: 1.2%;
  z-index: 9 !important;
  font-size: 16px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.ic-arrow-left-bg {
  fill: var(--beacon-color-1);
  background-color: var(--beacon-color-1);
  opacity: 0.9;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  padding-left: 2px;
  box-sizing: border-box;
}
.playerContainer {
  width: 100%;
  height: 100%;
}
.liverOverlay {
  background-color: black;
  top: 20px;
  z-index: 9;
  margin: 0;
  width: 15%;
  height: 72%;
  position: absolute;
  opacity: 0.3;
  display: none;
}
.liveContainer {
  width: 100%;
  height: 100%;
}
.videoContainer:hover .liverOverlay {
  display: block;
}
.liverOverlay:hover {
  opacity: 0.8;
}
/* .mySlider{
      width: 100%;
      height: 100%;
  } */
.mySliderItem {
  left: 10px;
  right: 10px;
  width: 100%;
  height: 100px;
  position: relative;
  /* background-color: red; */
  opacity: 2.3;
}
.slick-slide {
  height: 100%;
}
.slick-list {
  height: 456px !important;
}

/* sign in register page design */

.d-none {
  display: none;
}
._3nmo_success {
  color: #0b881e;
  text-align: center;
}
._3nmo_success ._3nmo_success {
  -webkit-animation-name: GrX0c;
  animation-name: GrX0c;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
._3nmo_success:empty {
  display: none;
}
.eyeicon {
  position: absolute;
  top: 18px;
  -webkit-transition: top 0.2s, font-size 0.2s;
  -o-transition: top 0.2s, font-size 0.2s;
  transition: top 0.2s, font-size 0.2s;
  opacity: 0.5;
  line-height: 18px;
  pointer-events: none;
  right: 17px;
}
.text-head {
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-top: 90px;
}
.subscribe-btn {
  background: none;
  color: #6e93ab;
  border: 1px solid #6e93ab;
  width: 170px;
  height: 50px;
  outline: none;
  margin-top: 35px;
  font-size: 17px;
  border-radius: 25px;
  transition-duration: 1s;
  margin-left: 25px;
}
.subscribe-btn:hover {
  background: #6e93ab !important;
  color: #fff !important;
}
.mytitle {
  padding: 12px;
  width: 250px;
  box-shadow: 0 10px 24px 0 rgba(238, 241, 250, 0.2);
  margin-bottom: 10px;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
  margin-right: 10px;
}
.subscribe-box {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 10px 24px 0 rgba(82, 91, 115, 0.12);
}
.payment-box {
  color: #fff;
  font-size: 20px;
}
.cards1 {
  margin-left: 15px;
  width: 90px !important;
  border-radius: 8px;
  margin-right: 15px;
  cursor: pointer;
}
.cards {
  width: 80px !important;
  border-radius: 8px;
  cursor: pointer;
}
.myFieldSet {
  width: 300px !important;
  box-shadow: 0 10px 24px 0 rgba(82, 91, 115, 0.12) !important;
  padding-bottom: 20px !important;
  border: -1px !important;
}
.card {
  margin-top: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 95%;
  margin: auto;
  background-color: #26262d !important;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.mycontainer {
  padding: 2px 16px;
  font-family: "Varela Round", sans-serif;
}
.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.modal-confirm.modal-dialog {
  margin-top: 80px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #82ce34;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}
.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #6fb32b;
  outline: none;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
/* sept 28 styling */
.slick-list {
  height: auto !important;
}
.watchBottom {
  margin-left: 480px;
}
@media (min-height: 400px) {
  .topContainer {
    min-height: 0vh;
  }
}
@media (min-height: 700px) {
  .topContainer {
    min-height: 0vh;
  }
}
@media (min-height: 1440px) {
  .topContainer {
    min-height: 0vh;
  }
}

.videoPlayerContainer {
  width: 100%;
  height: 100%;
}
.playerOverlay {
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 9;
  margin: 0;
  margin-top: 84px;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.3;
  display: none;
}
.videoContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.videoContainer:hover .playerOverlay {
  display: block;
}
.playerOverlay:hover {
  opacity: 0.8;
}
.videoPlayIcon {
  width: 4rem;
  height: 4rem;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: #fff;
  transition: color 0.2s;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.videoPlayIcon circle {
  transition: r 0.2s ease-in-out;
}

.videoPlayIcon:hover {
  color: red;
}

.kids-mode .videoPlayIcon:hover {
  color: #fea534;
}

.videoPlayIcon:hover circle {
  r: 28px;
}

@media (min-width: 768px) {
  .videoPlayIcon {
    width: 5rem;
    height: 5rem;
  }
}
.eyeIcon {
  position: absolute;
  top: 38%;
  right: 3%;
}
.eyeIcon:hover {
  color: red;
  cursor: pointer;
}

.playerBackButton {
  top: 4%;
  border-radius: 10%;
  border: 1px solid #fff;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  left: 89%;
  z-index: 9999999999999999;
  padding-left: 10px;
  padding-right: 10px;
}
.Select__listItem {
  width: 100%;
  height: 54px;
  padding: 18px 16px 0;
  border-bottom: 1px solid rgba(38, 38, 45, 0.1);
  color: rgba(38, 38, 45, 0.5);
  list-style: none;
  cursor: pointer;
}
.activate-style {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #1a1c21;
  min-height: inherit;
  margin-top: 90px;
  margin-bottom: 20px;
}
.styles-container-30ESb55q {
  height: 44px;
  position: fixed;
  top: 0px;
  width: 100%;
}
.activate-arrow-back {
  height: 44px;
  top: 0px;
  left: 0px;
  z-index: 4;
  padding: 10px;
  position: fixed;
}
.styles-backArrow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.styles-header {
  padding: 12px 0px;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: 0.29px;
  color: #ffffff;
  margin-top: 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-family: "Roboto-Bold", sans-serif;
}
.styles-box {
  background-color: #262931;
  border-radius: 5px;
  padding: 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
}
.styles-inputBoxContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
}
.styles-enterCodeWrap {
  display: flex;
  flex-direction: column;
  line-height: 1.41;
  margin-bottom: 48px;
}
.styles-linkTvTitle {
  display: inline;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 14px;
}
.styles-enterCodetext {
  color: #9ba1a9;
  font-size: 14px;
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
}
.styles-input-p5CThI-I {
  background-color: #262931;
  width: 100%;
  color: #ffffff;
  border: none;
  border-bottom: solid 1px #2175d9;
  outline: none;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  height: 60px;
  font-size: 30px;
  text-align: center;
  font-family: "Roboto-Regular", sans-serif;
  color: #2789ff;
  letter-spacing: 12px;
}
.styles-input {
  border: 1px solid;
  padding: 9px 0px;
}
.styles-buttonWrapper {
  margin-top: 8px;
  margin-bottom: 4px;
}
.styles-activeFromApp {
  width: 167px;
  margin-right: 68px;
  font-size: 14px;
  font-weight: 300;
  color: #838991;
  font-family: "Roboto-Regular", sans-serif;
}
.styles-primaryButton {
  width: 210px;
  height: 40px;
  border-radius: 4px;
  background-image: linear-gradient(to top, #2175d9, #2789ff);
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.29px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  font-family: "Roboto-Regular", sans-serif;
}
.activateContainer {
  background-color: #1a1c21;
  min-height: calc(100vh - 200px);
}
@media (max-width: 700px) {
  .styles-backBtn {
    display: none;
  }
  .activateContainer {
    padding-top: 0px;
  }
}
@media (max-width: 699px) {
  .activate-style {
    margin-top: 50px;
  }
  .styles-box {
    position: relative;
    padding: 10px;
    box-shadow: unset;
    background-color: #1a1c21;
  }
  .styles-inputBoxContainer {
    margin: 34px 26px;
  }
  .styles-enterCodetext {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    line-height: 1.44;
    color: #9ba1a9;
  }
  .styles-input {
    background-color: #1a1c21;
    height: 40px;
    caret-color: #9ba1a9;
  }
}
.timer {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 13pt;
  margin-bottom: 8px;
  padding-top: 12px;
}
.error-notification {
  border-color: red;
  color: red;
}
