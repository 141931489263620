.video-js .vjs-text-track {
  display: none;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  text-transform: capitalize;
  /* Transparent black background, or fallback to all black (oldIE) */
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
}
.vjs-menu-item-text {
  text-transform: capitalize;
}
.vjs-texttrack-settings {
  display: none;
}
.vjs-menu-item-text {
  content: "Off";
}

.vjs-control .vjs-button {
  content: "\f110";
  font-size: 1.8em;
  color: #ccc;
  font-family: VideoJS;
  line-height: 1.67;
  font-size: 1em;
  line-height: 3em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: inherit;
  font-family: Arial, Helvetica, sans-serif;
  width: 4em !important;
  content: "Quality";
}
.video-js button {
  background: 0 0;
  border: none;
  color: inherit;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  content: "Quality";
}
.video-js .vjs-volume-panel .vjs-volume-control {
  visibility: visible;
  opacity: 0;
  width: 1px;
  height: 1px;
  position: static;
  margin-left: auto;
  margin-right: auto;
  content: "Volume";
  /* margin-left: -1px; */
}
